.contenedores-cta {
  position: fixed;
  bottom: 50px;
  right: 50px;
  z-index: 99999;
  display: flex;
  flex-direction: column;
}

.contenedores-cta p {
  background-color: var(--color-gris-oscuro);
  color: var(--color-blanco);
  padding: 5px 10px;
  font-size: 12px;
  border-radius: 5px;
}

.contenedores-cta img {
  height: 50px;
  width: 50px;
  border-radius: 50px;
  margin: 10px;
  -webkit-box-shadow: 2px 2px 5px 0.8px rgba(0, 0, 0, 1);
  -moz-box-shadow: 2px 2px 5px 0.8px rgba(0, 0, 0, 1);
  box-shadow: 1px 1px 10px 0.1px rgba(0, 0, 0, 0.355);
}
.animacion-txt-contacto {
  animation:
    TextoAparecer 3s forwards,
    LoopText 13s infinite 3s;
}

@keyframes LoopText {
  0%,
  83%,
  100% {
    transform: rotate(0deg);
  }
  84%,
  95.5% {
    transform: rotate(10deg);
  }
  88.5% {
    transform: rotate(-10deg);
  }
}

@keyframes TextoAparecer {
  0% {
    opacity: 0;
    height: 0;
  }
  30%,
  50%,
  80%,
  100% {
    opacity: 1;
    height: auto;
    transform: rotate(0deg);
  }
  30% {
    transform: rotate(10deg);
  }
  50% {
    transform: rotate(-10deg);
  }
  80% {
    transform: rotate(10deg);
  }
}

.animacion-contacto {
  cursor: pointer;
  animation:
    Aparecer 3s forwards,
    LoopImg 13s infinite 3s;
}

@keyframes LoopImg {
  0%,
  83%,
  100% {
    opacity: 1;
    height: 50px;
    width: 50px;
    transform: rotate(0deg);
  }
  84%,
  95.5% {
    opacity: 1;
    height: 70px;
    width: 70px;
    transform: rotate(50deg);
  }
  88.5% {
    opacity: 1;
    height: 70px;
    width: 70px;
    transform: rotate(-50deg);
  }
}

@keyframes Aparecer {
  0% {
    opacity: 0;
    height: 0px;
    width: 0px;
    pointer-events: none;
  }
  30%,
  50%,
  80%,
  100% {
    opacity: 1;
    height: 70px;
    width: 70px;
    transform: rotate(0deg);
  }
  30% {
    transform: rotate(50deg);
  }
  50% {
    transform: rotate(-50deg);
  }
  80% {
    transform: rotate(50deg);
  }
}

.cta-texto {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.cta-texto {
  cursor: pointer;
}

/* BOTONES OCULTOS */

.btn-whatsapp {
    display: none;
  opacity: 0;
  transform: translateY(140px);
}
.animacion-whatsapp {
    display: flex;
  opacity: 1;
  transform: translateY(0px);
  animation: animacionWhatsapp 1s ease;
}

@keyframes animacionWhatsapp {
  0% {
    transform: translateY(140px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

.btn-correo {
    display: none;
  opacity: 0;
  transform: translateY(70px);
}

.animacion-correo {
    display: flex;
  opacity: 1;
  transform: translateY(0px);
  animation: animacionCorreo 1s ease;
}

@keyframes animacionCorreo {
  0% {
    transform: translateY(70px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

/*    M E D I A    Q U E R I E S     --    BIG-M E D I U M       */
@media screen and (max-width: 1380px) {
  
}


/*    M E D I A    Q U E R I E S     --    M E D I U M       */
@media screen and (max-width: 1024px) {
  .contenedores-cta {
    bottom: 110px;
    right: 30px;
  }
}

/*    M E D I A    Q U E R I E S     --   S M A L L       */
@media screen and (max-width: 600px) {
  .contenedores-cta {
    bottom: 150px;
    right: 10px;
  }
}