section#marcas_rep {
  margin-top: 3rem;
  padding-bottom: 5rem;
  height: max-content;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
}
section#marcas_rep h2 {
  padding-top: 2rem;
  text-align: center;
  font-size: 1.8rem;
  font-weight: 400;
  color: var(--color-azul);
}
section#marcas_rep p {
  width: 55%;
  text-align: center;
  color: var(--color-gris-oscuro);
  font-weight: 500;
  font-size: 1.05rem;
}

.marcas-container {
  display: inline-block;
  position: relative;
  max-width: 1920px;
}

.main__rep-container img {
  width: 90%;
  margin-left: 70px;
}
.arrow-btns {
  display: flex;
  justify-content: space-between;
  align-items: center; /* Centra verticalmente las flechas */
  position: absolute;
  z-index: 8;
  top: 0; 
  width: 100%;
  height: 100%; /* Ajusta la altura al 100% para centrar verticalmente */
}
.arrow-container {
  display: flex;
  justify-content: space-between;
  align-items: center; /* Centra verticalmente las flechas */
  position: absolute;
  z-index: 99;
  top: 0; /* Puedes ajustar esto según sea necesario */
  width: 100%;
  height: 100%; /* Ajusta la altura al 100% para centrar verticalmente */
}

.btn-marcas-arrows {
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  cursor: pointer;
  background-color: var(--color-blanco);
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn-prev-marcas {
  margin-left: 1rem; /* Ajusta el margen izquierdo */
}

.btn-next-marcas {
  margin-right: 1rem; /* Ajusta el margen derecho */
}

/*    M E D I A    Q U E R I E S     --    BIG-M E D I U M       */
@media screen and (max-width: 1380px) {
  
}

/*    M E D I A    Q U E R I E S     --    M E D I U M       */
@media screen and (max-width: 1024px) {
  .btn-prev-marcas,
  .btn-next-marcas {
    width: 2rem;
    height: 2rem;
  }
  .btn-prev-marcas {
    margin-left: 0.8rem; /* Ajusta el margen izquierdo */
  }
  .btn-next-marcas {
    margin-right: 0.8rem; /* Ajusta el margen derecho */
  }
  .main__rep-container img {
    margin-left: 30px;
    
    
  }
}

/*    M E D I A    Q U E R I E S     --   S M A L L       */
@media screen and (max-width: 600px) {
  section#marcas_rep p {
    width: 80%;
  }
  .main__rep-container {
    width: 350px;
    display: flex;
    justify-content: center;
    align-content: center;
  }
  .main__rep-container img {
    margin-right: 20px;
    width: 200px;
    max-height: 100px;
    object-fit: contain;
  }
}
