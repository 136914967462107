footer {
  background-color: var(--color-azul);
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100vw;
  z-index: 0;
  height: auto;
  margin-top: auto;
  position: relative;
  bottom: 0;
  
}

.footer_container {
  display: grid;
  grid-template-columns: 1fr 1.3fr 1.3fr 1fr;
  gap: 5rem;
  justify-content: space-between;
  padding-top: 4rem;
  padding-bottom: 4.5rem;
  padding-left: 3rem;
  padding-right: 3rem;
  max-width: 1920px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);

}

.info__empresa h4,
.servicio__cliente h4,
.oficina_info h4,
.footer-social__media h4 {
  color: var(--color-blanco);
  font-weight: 300;
  font-size: 0.8rem;
}

.info__empresa h3,
.servicio__cliente h3,
.oficina_info h3,
.footer-social__media h3 {
  color: var(--color-blanco);
  font-weight: 600;
  font-size: 0.9rem;
}

.footer-social__media {
  display: flex;
  flex-direction: column;
}
.footer-social__media ul {
  display: flex;
  padding: 1.3rem 0;
  gap: 0.5rem;
}
.footer-social__media li {
  height: 3rem;
}
.footer-social__media li >a >img {
  max-height: 100%;
  object-fit: contain;
}

h4.pais {
  color: var(--color-blanco);
}
.copyRight{
  color: var(--color-blanco);
  font-size: 11px;
  display: flex;
  align-self: center;
  justify-content: center;  
  margin-left: 5%;
  margin-right: 5%;
  padding-bottom: 1rem;
  text-align: center;
  
}
.footer-social__media > ul > li > a > img {
  width: auto;
}

/*    M E D I A    Q U E R I E S     --    BIG-M E D I U M       */
@media screen and (max-width: 1380px) {
  
}

/*    M E D I A    Q U E R I E S     --    M E D I U M       */
@media screen and (max-width: 1024px) {
  .footer_container {
    grid-template-columns: 1fr 1fr;
    gap: 6rem 2rem;
  }
  .oficina_info {
    position: relative;
    top: -120px;
  }
  .footer-social__media ul {
    max-width: 11rem;
   
  }
  .footer-social__media li >a > img {
    height: 2rem;
  }
  .footer-social__media li img {
    max-height: 100%;
    object-fit: contain;
  }
}

/*    M E D I A    Q U E R I E S     --   S M A L L       */
@media screen and (max-width: 600px) {
  footer {
    padding-top: 0;
  }
  .footer_container {
    width: 95%;
    display: grid;
    align-items: self-start;
    justify-content: flex-start;
  }
  .footer-social__media {
   
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  .footer-social__media ul {
    max-width: 10rem;
    
  }
  .footer-social__media li> a>img {
    height: 2rem;
  }

  .footer_container {
    grid-template-columns: 1fr;
    gap: 2rem;
  }
  .oficina_info {
    position: relative;
    top: 0px;
  }
}
