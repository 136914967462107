.hamb-menu-container{
    position: fixed;
    width: 100vw;
    top: 70px;
    z-index: 99;
    background-color: var(--color-blanco);
    height: max-content;
    box-shadow: 0 4px 6px -6px #222;
    padding: 1rem 0rem;
    
}
.hamb-menu-content{
    display: flex;
    justify-content: space-between;
  z-index: 99;
  padding: 0 2rem;
  color: #222;
}
.hamb-menu-container li a{
    color: var(--color-azul);
    font-weight: 400;
    font-size: 1.05rem;
}
.hamb-menu-container li a:hover{
    color: var(--color-morado);
}
@media screen and (max-width: 600px) {
  .hamb-menu-container{
    top: 64px;
}  
  .hamb-menu-content {
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }
    
  }