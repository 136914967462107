.contacto-main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10rem 0;
}


/* Main */
.contenido-contacto-main {
  display: grid;
  grid-template-columns: 0.7fr 1fr;
  align-items: top;
  width: var(--computer-width);
  margin: 0 auto;

  gap: 3rem;
}

/* Primera Columna */
.primera-columna-contenido {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3rem;
}

.img-wrapper-column-contanto img {
  height: 7rem;
  width: auto;
  padding-left: 30px;
}
.informacion-atencion {
  text-align: center;
}
.informacion-atencion h3,
.SocialMedia h3 {
  color: var(--color-naranjo);
  padding-bottom: 0.3rem;
  font-weight: 400;
  font-size: 1.2rem;
}

.informacion-atencion p {
  color: var(--color-gris-oscuro);
  font-weight: 500;
  font-size: 1.05rem;
}
.SocialMedia {
  display: flex;
  flex-direction: column;
  text-align: center;
}
.SocialMedia h3 {
  padding-bottom: 0.6rem;
}
.redes-sociales-contacto {
  display: flex;
  justify-content: space-around;
}
.redes-sociales-contacto img {
  cursor: pointer;
  height: 50px;
  width: 50px;
}
.segunda-columna-contenido{
 display: flex;
 flex-direction: column;
}
.segunda-columna-contenido h2 {
  font-size: 1.8rem;
  font-weight: 400;
  color: var(--color-naranjo);
  padding-left: 200px;
  padding-bottom: 3rem;
}


@media screen and (max-width: 1420px) {
 
 
  .segunda-columna-contenido h2 {
    
    padding-left: 120px;
    padding-bottom: 2rem;
  }
}
/*    M E D I A    Q U E R I E S     --    BIG-M E D I U M       */
@media screen and (max-width: 1380px) {
 
  .contacto-main {
    padding: 5rem 0;
  }
  .segunda-columna-contenido h2 {
    font-size: 1.4rem;
    padding-left: 50px;
  }
}

/*    M E D I A    Q U E R I E S     --    M E D I U M       */
@media screen and (max-width: 1024px) {
  .contacto-main {
    padding: 3rem 0;
  }
  .img-wrapper-column-contanto img {
    height: 5rem;
    padding-left: 20px;
  }
  .segunda-columna-contenido h2 {
    font-size: 1.4rem;
    padding-left: 0px;
    padding-bottom: 1rem;
  }
}

/*    M E D I A    Q U E R I E S     --   S M A L L       */
@media screen and (max-width: 600px) {
  .contenido-contacto-main {
    grid-template-columns: 1fr;
  }

  .header-container {
    height: auto;
  }
  .primera-columna-contenido {
    order: 2;
  }
  .segunda-columna-contenido h2 {
    text-align: center;
    padding: 2rem 0 0rem 0;
    font-size: 1.4rem;
    padding-left: 0px;
    padding-bottom: 1rem;
  }
}


/*TEST*/





/*    M E D I A    Q U E R I E S     --   B I G - M E D I U M       */
@media screen and (max-width: 1380px) {
  
}
/*    M E D I A    Q U E R I E S     --    M E D I U M       */
@media screen and (max-width: 1024px) {
}

/*    M E D I A    Q U E R I E S     --   S M A L L       */
@media screen and (max-width: 600px) {
  
 
  .contacto-main {
    padding: 3rem 0;
  }
}
