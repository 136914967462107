
.contenedor-grande {
  width: 100vw;
  max-width: 1920px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.title-wrapper {
  display: grid;
  grid-template-columns: 2fr 4fr;
  margin: 8rem 10rem 3rem 10rem;
  align-items: center;
}

.space-title {
  width: 400px;
}

.products-title {
  position: relative;
  right: 20px;
}

.contenedor-grande h2 {
  margin: 2rem;
  font-weight: 600;
  color: var(--color-azul);
}

.products-wrapper {
  gap: 5rem;
  margin-bottom: 5rem;
}

div.product-wrapper {
  display: grid;
  grid-template-columns: 2fr 4fr;
  margin: 0 10rem 3rem 10rem;
  align-items: center;
  gap: 4rem;
}

.product-img {
  max-width: auto;
  max-height: 250px;
  animation: moveImage 1s;
}
.product-img img {
  max-width: auto;
  max-height: 250px;
  object-fit: contain;
}

.product-info {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 2rem 0;
}

.modelo {
  color: var(--color-azul);
  font-weight: 700;
}
.fabrica {
  color: var(--color-naranjo);
  font-weight: 700;
}
.product-desc {
  text-align: justify;
  color: var(--color-gris-oscuro);
  font-weight: 500;
  font-size: 1.05rem;
}
.product-desc li{
  
  list-style-type: disc;
}
.test-parcel{
  
}

@keyframes moveImage {
  from {
    transform: translateX(-500px);
  }
  to {
    transform: translateX(0px);
  }
}
ul.slick-dots {
 display: none;
}


/* Prodcutos Inicios */
@media screen and (min-width: 1380px) {

  .prod-inicio-wrapper {
    width: 100vw;
    padding: 150px 0 50px ;
    min-height: 400px;
    display: flex;
    justify-content: center;
  }
}

.prod-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.prod-content h2 {
  font-size: 1.8rem;
  font-weight: 400;
  color: var(--color-azul);
  padding: 0rem 0 3rem 0;
}

.categorias-prd-inicio {
  display: flex;
}
.prod-inicio-categorias h3 {
  font-size: 1.2rem;
  font-weight: 400;
  padding: 0 2rem 0.5rem;
  color: var(--color-azul);
}

.prod-inicio-links h4 {
  font-size: 0.8rem;
  font-weight: 400;
  padding: 0 2rem;
  color: var(--color-azul);
}
.prod-inicio-links h4:hover {
  color: var(--color-morado);
}

/* Productos Modal */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 15px;
  width: 700px;
  height: 700px;
  display: flex;
  justify-content: center; /* Centrado horizontal */
  align-items: center;    /* Centrado vertical */
  overflow: hidden;
}

.modal-slide {
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content img{
  width: 600px;
  height: 600px;  
}


 .modal-slide img {
  object-fit: contain;
  margin-left: 30px;
  margin-top: 30px;
}
/* Flechas personalizadas solo para el modal */
.modal .custom-arrow svg {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 1001;
  top: 50%;
  transform: translateY(-50%);
  background-color: var(--color-blanco);
  border-radius: 50%;
  width: 3rem;
  height: 3rem;
  cursor: pointer;
}

.modal .custom-arrow svg {
  width: 13px;
  height: 13px;
}

.modal .custom-arrow-next {
  right: 15px;
}

.modal .custom-arrow-prev {
  left: 15px;
}


/*    M E D I A    Q U E R I E S     --   B I G - M E D I U M       */
@media screen and (max-width: 1380px) {
  
}

/*    M E D I A    Q U E R I E S     --    M E D I U M       */
@media screen and (max-width: 1380px) {
  .prod-inicio-wrapper {
    padding-top: 2rem;
    padding-bottom: 3rem;
  }
  .categorias-prd-inicio {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
  }
  .prod-inicio-categorias h3 {
    padding: 0 2rem 0.2rem;
  }
  .title-wrapper {
    grid-template-columns: 1fr;
    margin: 0rem 10rem 3rem 10rem;
  }
  

  .products-title {
    text-align: center;
  }
  .products-wrapper {
    display: grid;
    grid-template-columns: 1fr;
    gap: 2rem;
  }
  div.product-wrapper {
    grid-template-columns: 1fr 1fr;
    margin: 0 2rem 3rem 2rem;
    gap: 2rem;
  }
  .product-desc {
    padding-right: 2rem;
  }
  .modal-content {
    padding: 10px;
  width: 500px;
  height: 500px;
  }
  .modal-content img{
    width: 400px;
    height: 400px;  
  }
  .modal-slide img {
    margin-left: 35px;
    margin-top: 35px;
  }
}

/*    M E D I A    Q U E R I E S     --   S M A L L       */
@media screen and (max-width: 600px) {
  .categorias-prd-inicio {
    display: grid;
    grid-template-columns: 1fr;
    gap: 2rem;
  }
  .products-title {
    left: 10px;
  }
  div.product-wrapper {
    grid-template-columns: 1fr;
    margin: 0 1.5rem;
    gap: 0rem;
  }
  .product-desc {
    padding-right: 0rem;
  }
  .products-wrapper {
    display: grid;
    grid-template-columns: 1fr;
    gap: 2rem;
  }
  .modal-content {
    padding: 5px;
  border-radius: 15px;
  width: 350px;
  height: 350px;
  }
  .modal-content img{
    padding: 5px;
    width: 250px;
    height: 250px;  
  }
  .modal-slide img {
    margin-left: 35px;
    margin-top: 35px;
  }
}
