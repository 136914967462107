@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600&display=swap');

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
  overflow-x: hidden;
}

:root {
  --color-azul: #313e68;
  --color-turqueza: #10a8ba;
  --color-blanco: #f2f2f2;
  --color-naranjo: #ec9465;
  --color-gris-claro: #d5d5d5;
  --color-gris-medio: #9d9b9b;
  --color-gris-oscuro: #6b6b6c;
  --color-negro: #2b2b2b;
  --color-morado: #7f98fe;

  --transition: all 300ms ease;

  --computer-width: 90%;
  --tablet-width: 93%;
  --mobile-width: 96%;
}

html {
  scroll-behavior: smooth;
  height: 100%;
}

::-webkit-scrollbar {
  display: none;
}

body {
  font-family: 'Poppins', sans-serif;
  background-color: #fff;
  overflow-x: hidden;
  margin: 0;
  padding: 0;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.espacio-navbar {
  height: 0px;
}

.container {
  width: 100vw;
  margin: 0 auto;
  max-width: 1920px;
}

h1,
h2,
h3,
h4,
h5 {
  color: var(--color-negro);
}

h1 {
  font-size: 3rem;
}
h2 {
  font-size: 2rem;
}
h3 {
  font-size: 1rem;
}
h4 {
  font-size: 0.8rem;
}
img {
  display: block;
  width: 100%;
  object-fit: cover;
}

a {
  color: var(--color-blanco);
  transition: var(--transition);
  cursor: pointer;
}

a:hover {
  color: var(--color-morado);
}

.btn {
  width: max-content;
  font-weight: 400;
  font-size: 1rem;
  display: inline-block;
  color: var(--color-blanco);
  background-color: var(--color-azul);
  padding: 0.75rem 1.8rem;
  border-radius: 1.5rem;
  cursor: pointer;
  border: 1px solid var(--color-azul);
  transition: var(--transition);
}

.btn:hover {
  background: var(--color-naranjo);
  color: var(--color-azul);
  border-color: transparent;
}




ul.custom-list li {
  display: flex;                  
  align-items: baseline;       
          
}



ul.custom-list p {
  margin-left: 3px;                      
  word-wrap: break-word;     
  flex: 1;      
}
.custom-list-sub-list{
  padding-left: 20px;
}
.custom-list-sub-list span{
  font-size: 20px;
}





/*    M E D I A    Q U E R I E S     --    M E D I U M       */
@media screen and (max-width: 1380px) {
  .espacio-navbar {
    height: 70px;
  }
}

/*    M E D I A    Q U E R I E S     --   S M A L L       */
@media screen and (max-width: 600px) {
  .espacio-navbar {
    height: 64px;
  }
}



/*    SERVICIO TEC / NOSOTROS / CONTACTO    */
.wrapper_information{
  background-repeat: no-repeat;
  background-size: contain;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.serv_bg{
  background-image: url('https://lh3.googleusercontent.com/d/1JymSeOE0CG1kEbKmykL0hBSh22izFn2Y');
}
.nosotros_bg{
  background-image: url('https://lh3.googleusercontent.com/d/1RBp9Zd89AJcaJobWNAnMRJMDz9cgspgc');
}
.contacto_bg{
  background-image: url('https://lh3.googleusercontent.com/d/1QckmOktyBpxm9bevNPK5nSMBPCeWD6S1');
}


.header-informations {
  position: relative;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.big-space{
  height: 400px;
  
}

.content-information {
  background: linear-gradient(
    to top, 
    rgb(255, 255, 255) 0%,
    rgba(255, 255, 255, 0.7) 75%,
    rgba(255, 255, 255, 0) 100%
  );
  padding: 50px 80px;
}

.content-information h2{
  line-height: 1.2;
  font-weight: 600;
  font-size: 40px;
  padding-bottom: 1.5rem;
  color: var(--color-azul);
}
.content-information h2 span{
  display: block;
  line-height: inherit;
}

.content-information p {
  color: var(--color-gris-oscuro);
  font-size: 24px;
  line-height: 1.2;
}





/*    M E D I A    Q U E R I E S     --    BIG-M E D I U M       */
@media screen and (max-width: 1380px) {
  .big-space{
    height: 300px;
  }
  .content-information {
    padding: 50px 60px;
  }
  .content-information p{
    font-size: 20px;
  }
}

/*    M E D I A    Q U E R I E S     --    M E D I U M       */
@media screen and (max-width: 1024px) {
  .big-space{
    height: 200px; 
  }
  .content-information h2{
    font-size: 30px;
  }
  .content-information p{
    font-size: 18px;
  }
  .content-information {
    padding: 50px 50px;
  }
}

/*    M E D I A    Q U E R I E S     --   S M A L L       */
@media screen and (max-width: 600px) {
  .big-space{
    height: 100px;
  }
  .content-information h2{
    font-size: 20px;
  }
  .content-information p{
    font-size: 16px;
  }
  .content-information {
    padding: 50px 40px;
  }
  .content-information {
    background: linear-gradient(
      to top, 
      rgb(255, 255, 255) 0%,
      rgba(255, 255, 255, 0.7) 80%,
      rgba(255, 255, 255, 0) 100%
    );}
  }