@media screen and (min-width: 1380px) {
nav {
  position: absolute;
  top: 80px;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 4.5rem;
  background-color: var(--color-azul);
  
  z-index: 999;
  width: 90vw;
  margin: 0 auto;
  max-width: 1920px;
  
  
}

.nav__container {
  height: 100%;
  display: grid;
  grid-template-columns: 2fr 4fr 1.5fr 0.25fr;
  justify-items: space-between;
  align-items: center;
  position: relative;
  text-transform: uppercase;
  color: var(--color-blanco);
  font-size: 1rem;
}}

.nav__container a.logo {
  width: 10rem;
}

.nav__links {
  display: flex;
  justify-self: flex-end;
  gap: 1.5rem;
  padding-right: 1rem;
}
.nav__links .active {
  color: var(--color-morado);
}
.nav__links span {
  position: relative;
  top: 0.6rem;
  font-size: 1.4rem;
}
.menu_drop {
  display: none;
  transition: var(--transition);
}
.menu_drop.active {
  display: block;
}

/* only display on medium and small devices*/
.nav__toggle-btn {
  display: none;
}

.nav__search {
  display: flex;
  justify-self: flex-end;
  padding-right: 0.5rem;
  
}

.logo {
  max-height: 100%;
  width: auto;
  float: none;
  margin-left: 20px;
}

.logo__sm-screen {
  display: none;
}

/*    M E D I A    Q U E R I E S     --    M E D I U M       */
@media screen and (max-width: 1380px) {
  nav {
    
    z-index: 999;
    background-color: var(--color-azul);
    position: fixed;
    right: 0;
    top: 0;
    width: 100vw;
  }
  .nav__container {
    height: 100%;
    display: grid;
    grid-template-columns: 2fr 4fr 0.5fr 0.25fr;
    justify-items: space-between;
    align-items: center;
    position: relative;
    text-transform: uppercase;
    color: var(--color-blanco);
    font-size: 1.09rem;
  }
  
  
  .nav__links {
    display: none;
  }
  .nav__toggle-btn {
    display: block;
    font-size: 1.5rem;
    background: transparent;
    color: var(--color-blanco);
  }
  .nav__toggle-btn:hover {
    color: var(--color-morado);
  }
}

/*    M E D I A    Q U E R I E S     --   S M A L L       */
@media screen and (max-width: 600px) {
  .nav__container a.logo {
    width: 9rem;
  }
}
