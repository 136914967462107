.header__container {
  display: grid; /* O usa flex si prefieres */
  grid-template-columns: 5fr 7fr; /* Ajusta la proporción aquí */
  gap: 0rem;
  height: auto;
}


.header__left {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
  padding: 170px 0px 50px 50px;
}

.row_header_left{
  display: flex;
  align-items: center;
  width: 100%;
  gap: 5px;
}

.imagen_pentacam{
  flex: 2;
  
}

.texto_pentacam{
  flex: 5;
  display: flex;
    flex-direction: column;
    align-items: center; 
    text-align: center;
    
}
.imagen_pentacam_mobil{
  display: none
}
.oculus_logo{
  width: 200px;
  padding-bottom: 20px;
}

.texto_pentacam h1{
  font-size: 1.8rem;
  font-weight: 600;
}
.texto_pentacam p{
  font-size: 1rem;
}
.promo-text{
  padding: 10px 50px;
}
.promo-availability{
  padding: 10px 0px 30px;
}

.mainheader__btn{
  width: max-content;
  font-weight: 400;
  font-size: 1.5rem;
  display: inline-block;
  color: var(--color-blanco);
  background-color: var(--color-azul);
  padding: 0.3rem 2.5rem;
  border-radius: 1.5rem;
  cursor: pointer;
  border: 1px solid var(--color-azul);
  transition: var(--transition);
}


.mainheader__btn:hover {
  background: var(--color-naranjo);
  color: var(--color-azul);
  border-color: transparent;
}


.header__right {
    position: relative;
    height: auto; /* Cambia a auto para adaptarse al contenido */
    background-image: url('https://lh3.googleusercontent.com/d/1X0SVChVj5whGAvlZPQT1Iui06L_icuXw') ;
    background-repeat: no-repeat;
    background-size: cover;
  }

.arrow_header {
  position: relative;
  top: 5px;
  left: 8px;
}

.video-container_header {
  position: relative;
  width: 100%;
  max-width: 400px;
  background: rgba(0, 0, 0, 0.8);
  padding: 10px;
  border-radius: 10px;
}

/* BOTON */
@media screen and (max-width: 1380px) {
  
  .header__left h2{
    padding-top: 0px;
  }

  }

  /* Estilos del modal de video */
.video-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.video-container_header {
  position: relative;
  background: white;
  padding: 20px;
  border-radius: 10px;
  width: 80%;
  max-width: 700px;
}

.video-container_header video {
  width: 100%;
  border-radius: 10px;
}

/* Botón de cierre */
.close-btn {
  position: absolute;
  top: 10px;
  right: 15px;
  background: var(--color-azul);
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  font-size: 18px;
  border-radius: 50%;
}


/*    M E D I A    Q U E R I E S     --    M E D I U M       */
@media screen and (max-width: 1024px) {
  .header__container {
    grid-template-columns: 1fr;
  }
  .header__right {
    display: none;
  }
 
  .header__left {
    flex-basis: 100%;
    padding: 30px 40px;
  }

  .header__left h2 {
    width: 70%;
  }
  .header__left h2>span{
    position: relative;
    bottom: 8px;
  }
  
}

/*    M E D I A    Q U E R I E S     --   S M A L L       */
@media screen and (max-width: 600px) {
  .imagen_pentacam{
    display: none;
  }
  .imagen_pentacam_mobil{
    display: block;
    padding-bottom: 20px;
    width: 100px;
  }
  .header__left h2 {
    font-size: 1.5rem;
  }
  .evo__logo, .evo__logo2 {
    transform: scale(0.8);
  }

  .header__left h2 {
    width: 70%;
    padding-top: 50px;
  }
  .evo__logo {
    position: relative;
    height: 7rem;
  }
  .evo__logo2 {
    position: relative;
    top: -1rem;
    left: 0rem;
    height: 4rem;
    width: auto;
  }
  .texto_pentacam h1{
    font-size: 1.6rem;
  }
  .promo-text{
    padding: 10px 10px;
  }
 
}
