

/* MAIN */
.serv-main-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 100px;
  padding-bottom: 10rem;
}
.serv-main-wrapper h2 {
  font-size: 1.8rem;
  font-weight: 400;
  color: var(--color-naranjo);
  position: relative;
  top: 8px;
}
.serv__main {
  display: grid;
  grid-template-columns: 0.7fr 1fr;
  align-items: top;
  width: var(--computer-width);
  margin: 0 auto;
  gap: 3rem;
}
.serv__main.row-1{
  padding-bottom: 2rem;
}

/* MAIN  ----  CONTACTO*/

.serv__ntrs-serv{
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--color-gris-oscuro);
  font-weight: 500;
  font-size: 1.3rem;
}

.serv__contacto {
  display: flex;
  flex-direction: column;
  gap: 3rem;
  align-items: center; 
}


.serv__contacto-what {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.serv__ntrs-serv h3,
.serv__contacto-what h3,
.serv__contacto-horario h3 {
  color: var(--color-naranjo);
  padding-bottom: 0.3rem;
  font-weight: 400;
  font-size: 1.8rem;
}

.serv__contacto-what--info {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}
.serv__contacto-what--info img {
  height: 2rem;
  width: 2rem;
}
.serv__contacto-what--info span {
  color: var(--color-gris-oscuro);
  font-weight: 500;
  font-size: 1.3rem;
}

.serv__contacto-horario {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.serv__contacto h3 {
  color: var(--color-naranjo);
}
.serv__contacto-horario p {
  color: var(--color-gris-oscuro);
  font-weight: 500;
  font-size: 1.3rem;
}
/* form */
.ser-form-title{
  display: flex;
  align-items: flex-end;
  gap: 10px;
  justify-content: center;
  padding-right: 10%;
}
.ser-form-title img{
  width: 100px;
}
/*  TEAMVIEWER   */

.teamViewer a {
  color: rgb(29, 88, 213);
}
.serv__teamViewer {
  margin-bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.serv__teamViewer img {
  height: 5rem;
  width: auto;
}
.serv__teamViewer p {
  padding: 2rem 0;
  text-align: center;
}


/*    M E D I A    Q U E R I E S     --    BIG-M E D I U M       */
@media screen and (max-width: 1380px) {
  
}

/*    M E D I A    Q U E R I E S     --    M E D I U M       */
@media screen and (max-width: 1024px) {
  .serv__ntrs-serv h3,
  .serv__contacto-what h3,
  .serv__contacto-horario h3,
  .serv-main-wrapper h2 {
    font-size: 1.6rem;
  }
  .ser-form-title img {
    width: 85px;
  }
  .ser-form-title{
    padding-left: 4%;
  }
  .serv__ntrs-serv ul,
  .serv__contacto-what--info span,
  .serv__contacto-horario p {
    font-size: 20px;
    }
 
}

/*    M E D I A    Q U E R I E S     --   S M A L L       */
@media screen and (max-width: 600px) {
  .serv__main {
    grid-template-columns: 1fr;
  }
  .header-servicioTec {
    height: auto;
  }
  .serv__contacto{
    order: 2;
  }
  .serv-main-wrapper h2 {
    padding: 2rem 0 0rem 0;
  }
  .serv__teamViewer img {
    height: 4rem;
    width: auto;
  }
  .big-space_serctec{
    height: 200px;
  }
  .serv-main-wrapper {
    padding-top: 0px;
   
  }
  .serv__ntrs-serv h3,
  .serv__contacto-what h3,
  .serv__contacto-horario h3,
  .serv-main-wrapper h2 {
    font-size: 1.4rem;
   
  }
  .ser-form-title img {
    width: 80px;
}
.ser-form-title{
  padding-left: 8%;
}
.serv__ntrs-serv ul,
.serv__contacto-what--info span,
.serv__contacto-horario p {
font-size: 18px;
}

}
