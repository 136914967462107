.title-nosotros{
  text-align: center;
}
 .content-nosotros h2, .main-nosotros h2{
  text-align: center;
  font-weight: 200px;
  line-height: 1.2;
  font-weight: 500;
  font-size: 40px;
  padding-bottom: 1.5rem;
  color: var(--color-azul);
}
.content-nosotros p {
  color: var(--color-gris-oscuro);
  font-size: 24px;
  line-height: 1.2;
}
.main-nosotros {
  padding: 100px 80px;
}
.main-nosotros h2 {
  text-align: center;
  font-weight: 600;
  color: var(--color-azul);
  margin-bottom: 3rem;
}
.caracteristicas-nosotros {
  display: flex;
  gap: 5rem;
  align-items: flex-start;
  justify-content: center;
}
.caracteristica-nosotros {
  width: 20rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 1.2rem;
}
img#compromiso {
  width: auto;
  height: 100px;
  padding: 10px 20px;
}
.caracteristica-nosotros h3 {
  color: var(--color-naranjo);
  font-size: 1.4rem;
}
.caracteristica-nosotros img {
  width: auto;
  height: 100px;
}
.caracteristica-nosotros p {
  text-align: center;
  font-weight: 500;
  color: var(--color-gris-oscuro);
  font-size: 1.05rem;
}
.linea-vertical-nosotros {
  height: 12rem;
  width: 0.05rem;
  background-color: var(--color-gris-medio);
}

/*    M E D I A    Q U E R I E S     --   B I G - M E D I U M       */
@media screen and (max-width: 1380px) {
  .caracteristica-nosotros h3 {
    font-size: 1rem;
    text-align: center;
  }
  .main-nosotros h2{
    font-size: 35px;
  }
}

/*    M E D I A    Q U E R I E S     --    M E D I U M       */
@media screen and (max-width: 1024px) {
  .caracteristicas-nosotros {
    display: grid;
  }
  .caracteristica-nosotros {
    width: 30rem;
  }
  .linea-vertical-nosotros {
    height: 0.05rem;
    width: 30rem;
  }
  

}

/*    M E D I A    Q U E R I E S     --   S M A L L       */
@media screen and (max-width: 600px) {
  .caracteristica-nosotros {
    width: 15rem;
  }
  .header-nosotros {
    height: auto;
  }
  .headline-small {
    text-align: center;
  }
  .linea-vertical-nosotros {
    display: none;
  }
  .main-nosotros h2{
    font-size: 25px;
  }
}
