.categoriasLista-wrapper {
  width: 100vw;
  padding-top: 2rem;
  min-height: 450px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3rem;
}

.categoriasLista-wrapper h2 {
  margin-top: 8rem;
  font-size: 1.8rem;
  font-weight: 400;
  color: var(--color-azul);
}
.categoriaLista-contenido {
  display: flex;
  gap: 3rem;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
}
.categoriaLista-img {
  background-color: var(--color-azul);
  width: 10rem;
  height: 10rem;
  border-radius: 50%;
  display: grid;
  align-items: center;
  justify-items: center;
}
.orange-background, .orange-background-height {
  background-color: var(--color-naranjo);
}
.categoriaLista-img img {
  width: 80%;
  aspect-ratio: 3/2;
  object-fit: contain;
}
.orange-background-height img{
 width: 100%;
}
.productoLista {
  color: var(--color-azul);
}
.productoLista:hover {
  color: var(--color-morado);
}
.btn_lista-productos{
  margin-top: 20px;
}


/*    M E D I A    Q U E R I E S     --    BIG-M E D I U M       */
@media screen and (max-width: 1380px) {
  
}
/*    M E D I A    Q U E R I E S     --   M E D I UM       */
@media (max-width: 1380px) {
  .categoriasLista-wrapper h2 {
    margin-top: 0px ;
  }
}
  
/*    M E D I A    Q U E R I E S     --   S M A L L       */
@media screen and (max-width: 600px) {
  .categoriaLista-img {
    display: none;
  }
}
